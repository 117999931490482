import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "./common/Table";
import Filter from "./common/Filter";
import LoadingIndicator from "./common/LoadingIndicator";
import { useFullName } from "./utilities";
import { AlertDialog } from "./patient/AlertDialog";
import { withMixpanel } from "react-mixpanel-browser";
import { connect } from "react-redux";
import { setAlertFilters } from "./store/actions";
import { getCategoryObjects, getStatusObjects } from "./store/selectors";

export const config = (categories, types) => {
  return {
    columns: [
      {
        title: "Status",
        transformer: (alert) => {
          return <AlertDialog alert={alert} />;
        },
        sortValue: (data) => data.status_id,
        sortable: true,
        headerClassName: "text-center",
      },
      {
        title: "Alert Date",
        key: "insert_date",
        transformer: (data) => moment(data).format("M/DD/YYYY"),
        sortValue: (data) => moment(data.insert_date).unix(),
        sortable: true,
      },
      {
        title: "Documentation Date",
        key: "date",
        transformer: (data) => moment(data).format("M/DD/YYYY"),
        sortValue: (data) => moment(data.date).unix(),
        sortable: true,
      },
      {
        title: "Patient",
        transformer: (alert) => {
          return (
            <Link
              to={{
                pathname: `patients/redirect/${alert.patient.id}`,
                search: `?date=${moment(alert.date).format("YYYY-MM-DD")}`,
              }}
            >
              {useFullName(alert.patient)}
            </Link>
          );
        },
        sortKey: "patient.lastName",
        sortable: true,
      },
      {
        title: "Location",
        transformer: (alert) => {
          return alert.facility.location;
        },
        sortKey: "facility.location",
        sortable: true,
      },
      {
        title: "Category",
        transformer: (alert) => {
          return categories && categories[alert.category_id] ? categories[alert.category_id].name : "-";
        },
        sortKey: "category_id",
        sortable: true,
      },
      {
        title: "Description",
        key: "description",
        sortable: true,
      },
    ],
    defaultSort: {
      column: 1,
      direction: "descending",
    },
    search: {
      keys: [
        {
          name: "patient.lastName",
          weight: 0.5,
        },
        {
          name: "patient.firstName",
          weight: 0.5,
        },
      ],
      placeholder: "Search by Patient",
    },
    category: "Alert",
  };
};

export const AlertsList = (props) => {
  const {
    alertFilters,
    alerts,
    categories,
    isLoading,
    types,
    setAlertFilters,
    statusOptions,
    categoryOptions,
    user,
    mixpanel,
  } = props;

  const handleFilterChange = (filterKey, selectedValues) => {
    const email = user.email;

    if (selectedValues.length > alertFilters[filterKey].length) {
      const newFilter = selectedValues
        .filter((value) => !alertFilters[filterKey].includes(value))
        .map((filters) => filters.value);

      mixpanel.track("Alert filter added", {
        email: email,
        [filterKey]: newFilter,
      });
    }
    setAlertFilters(filterKey, selectedValues);
  };

  const filterAlerts = () => {
    const { locations = [], status = [], categories = [] } = Object.entries(alertFilters).reduce(
      (selectedVals, [key, selected]) => ({
        ...selectedVals,
        [key]: selected.map(({ value }) => value),
      }),
      {}
    );

    return alerts.filter((alert) => {
      return (
        (locations.length === 0 || locations.includes(alert.facility.location)) &&
        (status.length === 0 || status.includes(alert.status_id)) &&
        (categories.length === 0 || categories.includes(alert.category_id))
      );
    });
  };

  // JS Object representing the options in the drop down menus for the respective filters.
  let filterOptions = {
    locations: [...new Set(alerts.map((alert) => alert.facility.location))].map((loc) => ({ label: loc, value: loc })),
    status: statusOptions,
    categories: categoryOptions,
  };

  const alertList = filterAlerts();

  if (isLoading && !alerts.length) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Table
        config={config(categories, types)}
        data={alertList}
        headerContent={
          <div className="flex-column">
            {Object.keys(filterOptions).map((filterKey) => {
              const label = filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
              return (
                <Filter
                  className="flex-1-auto p-xs"
                  key={filterKey}
                  label={label}
                  value={alertFilters[filterKey]}
                  options={filterOptions[filterKey]}
                  onChange={(values) => handleFilterChange(filterKey, values)}
                  isMulti
                />
              );
            })}
          </div>
        }
      />
    </>
  );
};

AlertsList.defaultProps = {
  alertFilters: {},
  alerts: [],
  locations: [],
  categories: [],
};

function mapStateToProps(state) {
  const {
    filters: { alertFilters },
  } = state;
  return {
    alertFilters,
    user: state.user,
    statusOptions: getStatusObjects(state),
    categoryOptions: getCategoryObjects(state),
  };
}

export default connect(mapStateToProps, {
  setAlertFilters,
  getStatusObjects,
  getCategoryObjects,
})(withMixpanel(AlertsList));
